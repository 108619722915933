@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic, oblique;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic, oblique;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic, oblique;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic, oblique;
}

@font-face {
	font-family: 'Roboto';
	src: url('../assets/fonts/Roboto-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

a {
	color: inherit;
}

.ui.segment {
	padding: 0;
	border: 0;
	box-shadow: none;
}

.card .content {
	padding: 20px !important;
}

.ui.labeled.icon.menu .item,
.ui.inverted.menu .item,
.ui.menu .item > i.icon,
i.big.icon {
	color: inherit;
}

.ui.tab.active,
.ui.tab.open {
	padding: 0;
}

.ui.labeled.icon.button {
	margin: 10px 0 10px 0;
}

.ui.negative.message {
	background-color: var(--neutral-900);
	box-shadow: none;
}

#tv_chart_container,
#tv_chart_container_mobile {
	position: relative;
}

.ui.sortable.table thead th:hover {
	background: var(--data-table-header-hover);
}

.ui.divider {
	margin: 32px 0;
}

.ui.button.loading {
	pointer-events: none !important;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
	height: 40px;
}

.ui.form .ui.input .ui.label {
	height: 40px;
	display: flex;
	align-items: center;
}

/* prevent dimmers stacking */
.ui.page.dimmer ~ .ui.page.dimmer:not(.force_dimmer) {
	visibility: hidden !important;
}

// Neutrals
@neutral-100: #cacfe0;
@neutral-200: #9397bf;
@neutral-300: #757ba9;
@neutral-400: #3e4879;
@neutral-500: #30375f;
@neutral-600: #262d54;
@neutral-700: #24284f;
@neutral-800: #171d3c;
@neutral-850: #121730;
@neutral-900: #0f1328;
@neutral-950: #080d22;
@neutral-1000: #090c1b;

// Logo
// Note: Logo also has @yellow-400 but it's defined below;
@green-500: #00c400;
@yellow-500: #fdcc27;

// Blue
@blue-100: #475aff;
@blue-200: #4449e4;
@blue-400: #4200ff;
@blue-600: #2f34ca;
@blue-800: #262ab6;
@blue-900: #0a0c3f;
@blue-950: #040630;
@blue-1000: #01061c;

// Red
@red-200: #f87070;
@red-400: #ed5252;
@red-600: #cd4040;
@red-800: #be3434;
@red-900: #7c2828;
@red-1000: #1d0202;

// Yellow
@yellow-200: #ffab4a;
@yellow-400: #fd9a27;
@yellow-600: #ec8c1d;
@yellow-800: #dd7b08;
@yellow-900: #985b14;
@yellow-1000: #432400;

// Green
@green-200: #63feeb;
@green-400: #00ffe0;
@green-600: #0cebd0;
@green-800: #06d8bf;
@green-900: #05a491;
@green-1000: #001a16;

// Purple
@purple-200: #9546e5;
@purple-400: #7b18de;
@purple-600: #7116cb;
@purple-800: #6815ba;
@purple-900: #520d97;
@purple-1000: #2e0755;

@white: #ffffff;

.hexToRGB(@color) {
	@result: red(@color), green(@color), blue(@color);
}

:root {
	--call-to-action-gradient-old: linear-gradient(90deg, #373cd9 2.12%, #243afa 97.88%);
	--call-to-action-gradient: linear-gradient(270deg, @blue-400 2.12%, #243afa 97.88%);
	--active-vertical-gradient: linear-gradient(0deg, #373cd900 0%, #373cd91a 100%);
	--selectors-selected-gradient: linear-gradient(270deg, #373cd9 2.12%, #243afa 97.88%);
	--order-summary-gradient: linear-gradient(180deg, #373cd9 0%, #243afa 100%);
	--info-message-gradient: linear-gradient(
		90deg,
		rgba(55, 60, 217, 0.1) 0%,
		rgba(16, 20, 39, 0.1) 101.58%
	);
	--white: @white;

	--primary-100: @blue-100;
	--primary-200: @blue-200;
	--primary-400: @blue-400;
	--primary-400-rgb: .hexToRGB(@blue-400) [ @result];
	--primary-600: @blue-600;
	--primary-800: @blue-800;
	--primary-800-rgb: .hexToRGB(@blue-800) [ @result];
	--primary-900: @blue-900;
	--primary-950: @blue-950;
	--primary-1000: @blue-1000;
	--primary-color: @blue-400;

	--red-200: @red-200;
	--red-400: @red-400;
	--red-600: @red-600;
	--red-800: @red-800;
	--red-900: @red-900;
	--red-1000: @red-1000;
	--red: @red-400;

	--yellow-200: @yellow-200;
	--yellow-200-rgb: .hexToRGB(@yellow-200) [ @result];
	--yellow-400: @yellow-400;
	--yellow-500: @yellow-500;
	--yellow-600: @yellow-600;
	--yellow-800: @yellow-800;
	--yellow-900: @yellow-900;
	--yellow-1000: @yellow-1000;

	--green-200: @green-200;
	--green-400: @green-400;
	--green-400-rgb: .hexToRGB(@green-400) [ @result];
	--green-500: @green-500;
	--green-600: @green-600;
	--green-800: @green-800;
	--green-900: @green-900;
	--green-1000: @green-1000;
	--green: @green-400;

	--purple-200: @purple-200;
	--purple-400: @purple-400;
	--purple-600: @purple-600;
	--purple-800: @purple-800;
	--purple-800-alpha: rgba(82, 13, 151, 0.5);
	--purple-900: @purple-900;
	--purple-1000: @purple-1000;

	--neutral-100: @neutral-100;
	--neutral-200: @neutral-200;
	--neutral-200-rgb: .hexToRGB(@neutral-200) [ @result];
	--neutral-300: @neutral-300;
	--neutral-300-rgb: .hexToRGB(@neutral-300) [ @result];
	--neutral: @neutral-300;
	--neutral-400: @neutral-400;
	--neutral-500: @neutral-500;
	--neutral-600: @neutral-600;
	--neutral-600-rgb: .hexToRGB(@neutral-600) [ @result];
	--neutral-700: @neutral-700;
	--neutral-800: @neutral-800;
	--neutral-800-rgb: .hexToRGB(@neutral-800) [ @result];
	--neutral-850: @neutral-850;
	--neutral-850-rgb: .hexToRGB(@neutral-850) [ @result];
	--neutral-900: @neutral-900;
	--neutral-900-rgb: .hexToRGB(@neutral-900) [ @result];
	--neutral-950: @neutral-950;
	--neutral-1000: @neutral-1000;
	--neutral-1000-rgb: .hexToRGB(@neutral-1000) [ @result];

	--text-color-secondary: @neutral-300;
	--text-color-default: @white;
	--text-color-default-rgb: .hexToRGB(@white) [ @result];

	--buy: var(--green);
	--buy-recently-updated: var(--green-200);
	--buy-button: var(--green-400);
	--buy-button-hover: var(--green-200);
	--sell: var(--red);
	--sell-recently-updated: var(--red-200);
	--sell-button: var(--red-400);
	--sell-button-hover: var(--red-200);
	--drawer-pill-buy: var(--green);
	--drawer-pill-sell: var(--red-600);
	--drawer-pill-traded: var(--neutral-400);
	--success: var(--green);
	--fail: var(--red);
	--buy-closed: var(--green-900);
	--sell-closed: var(--red-900);
	--drawer-pill-buy-closed: var(--green-900);
	--drawer-pill-sell-closed: var(--red-900);
	--paper-market-depth-offer-closed: fade(@neutral-300, 15%);
	--paper-market-depth-bid-closed: fade(@neutral-300, 10%);
	--paper-market-depth-closed-hover: fade(@neutral-300, 20%);
	--paper-market-depth-closed-highlight: fade(@neutral-300, 30%);

	--paper-bid-depth: fade(@green-800, 10%);
	--paper-bid-depth-hover: fade(@green-800, 20%);
	--paper-bid-depth-highlight: fade(@green-800, 45%);
	--paper-bid-depth-recently-updated: fade(@green-800, 30%);
	--paper-offer-depth: fade(@red-800, 15%);
	--paper-offer-depth-hover: fade(@red-800, 25%);
	--paper-offer-depth-highlight: fade(@red-800, 45%);
	--paper-offer-depth-recently-updated: fade(@red-800, 40%);

	/* Positive negative don't change in chinese, only in colorblind*/
	--positive: var(--green);
	--negative: var(--red);

	--text-for-buy: var(--neutral-900);
	--text-for-sell: var(--text-color-default);
	--text-for-traded: var(--text-color-default);

	&.alternative {
		--buy: var(--red);
		--buy-recently-updated: var(--red-200);
		--buy-button: var(--red-400);
		--buy-button-hover: var(--red-200);
		--sell: var(--green);
		--sell-recently-updated: var(--green-200);
		--sell-button: var(--green-400);
		--sell-button-hover: var(--green-200);
		--drawer-pill-buy: var(--red-600);
		--drawer-pill-sell: var(--green);
		--success: var(--green);
		--fail: var(--red);
		--buy-closed: var(--red-900);
		--sell-closed: var(--green-900);
		--drawer-pill-buy-closed: var(--red-900);
		--drawer-pill-sell-closed: var(--green-900);

		--positive: var(--red);
		--negative: var(--green);

		--text-for-buy: var(--text-color-default);
		--text-for-sell: var(--neutral-900);

		--paper-bid-depth: fade(@red-800, 10%);
		--paper-bid-depth-hover: fade(@red-800, 20%);
		--paper-bid-depth-highlight: fade(@red-800, 45%);
		--paper-bid-depth-recently-updated: fade(@red-800, 30%);
		--paper-offer-depth: fade(@green-800, 10%);
		--paper-offer-depth-hover: fade(@green-800, 20%);
		--paper-offer-depth-highlight: fade(@green-800, 45%);
		--paper-offer-depth-recently-updated: fade(@green-800, 40%);
	}

	&.colorblindness {
		--buy: var(--primary-200);
		--buy-button: var(--primary-200);
		--buy-button-hover: var(--primary-100);
		--sell: var(--yellow-200);
		--sell-button: var(--yellow-400);
		--sell-button-hover: var(--yellow-200);
		--drawer-pill-buy: var(--primary-200);
		--drawer-pill-sell: var(--yellow-200);
		--success: var(--primary-200);
		--fail: var(--yellow-200);
		--buy-closed: var(--primary-800);
		--sell-closed: var(--yellow-900);
		--drawer-pill-buy-closed: var(--primary-800);
		--drawer-pill-sell-closed: var(--yellow-900);

		--positive: var(--primary-200);
		--negative: var(--yellow-200);

		--text-for-buy: var(--text-color-default);
		--text-for-sell: var(--neutral-1000);

		--paper-bid-depth: fade(@blue-600, 10%);
		--paper-bid-depth-hover: fade(@blue-600, 20%);
		--paper-bid-depth-highlight: fade(@blue-600, 45%);
		--paper-bid-depth-recently-updated: fade(@blue-600, 30%);
		--paper-offer-depth: fade(@yellow-800, 10%);
		--paper-offer-depth-hover: fade(@yellow-800, 20%);
		--paper-offer-depth-highlight: fade(@yellow-800, 45%);
		--paper-offer-depth-recently-updated: fade(@yellow-800, 40%);
	}

	&.alternative.colorblindness {
		--buy: var(--yellow-200);
		--buy-button: var(--yellow-400);
		--buy-button-hover: var(--yellow-200);
		--sell: var(--primary-200);
		--sell-button: var(--primary-200);
		--sell-button-hover: var(--primary-100);
		--drawer-pill-buy: var(--yellow-200);
		--drawer-pill-sell: var(--primary-200);
		--success: var(--primary-200);
		--fail: var(--yellow-200);
		--buy-closed: var(--yellow-900);
		--sell-closed: var(--primary-800);
		--drawer-pill-buy-closed: var(--yellow-900);
		--drawer-pill-sell-closed: var(--primary-800);

		--positive: var(--yellow-200);
		--negative: var(--primary-200);

		--text-for-buy: var(--neutral-1000);
		--text-for-sell: var(--text-color-default);

		--paper-bid-depth: fade(@yellow-800, 10%);
		--paper-bid-depth-hover: fade(@yellow-800, 20%);
		--paper-bid-depth-highlight: fade(@yellow-800, 45%);
		--paper-bid-depth-recently-updated: fade(@yellow-800, 30%);
		--paper-offer-depth: fade(@blue-600, 10%);
		--paper-offer-depth-hover: fade(@blue-600, 20%);
		--paper-offer-depth-highlight: fade(@blue-600, 45%);
		--paper-offer-depth-recently-updated: fade(@blue-600, 40%);
	}

	// END NOT PART OF THEME
	--z-index-chat: 12;
	--z-index-drawer: 11;
	--z-index-create-order-drawer: 10;
	--z-index-toast-permanent: 95;
	--z-index-toast-trade-confirmation: 96;
	--z-index-toast-temporary: 97;
	// error should be displayed above dimmer
	--z-index-toast-error: 1001;
	--z-index-top-most: 1002;
	--z-index-modal: 1003;
	--z-index-tooltip: 1004; // tooltip must be on top of everything
	--z-index-toast-modal: 1005;

	// borders
	--default-border-radius: 2px;
	--big-border-radius: 5px;
	--bigger-border-radius: 16px;
	--simple-border-radius: 6px;
	--large-border-radius: 8px;

	// page elements
	--app-bar-height: 56px;
	--app-notifications-bar-height: 44px;
	--app-fullsize-content-height: calc(
		100vh - var(--app-bar-height) - var(--app-notifications-bar-height)
	);

	// components - create react components?
	--icon-small: 16px;
	--scrollbar-width: 10px;

	//TODO: review "--button-max-height" and -"-button-min-height" if they can be fully replaced by "--button-height"
	--button-max-height: 40px;
	--button-min-height: 36px;
	--button-height: 36px;

	--page-h-padding: 24px;

	@media (min-width: 1920px) {
		--button-height: 40px;
		--page-h-padding: 48px;
	}

	// comments
	--owner-comment-color: var(--purple-200);
	--counterparty-comment-color: var(--yellow-600);

	--counter-value-color: #161c58;
	--last-counter-value-color: #21269a;
	--counter-value-special-color: #3a2a28;
	--counter-value-special-border-color: var(--yellow-900);
	--last-counter-value-special-color: var(--yellow-800);
	--last-counter-value-special-border-color: var(--yellow-200);

	// login page colors
	--login-background-color: #fcf0ae;
	--login-form-color: @white;
	--login-accent-color: #ffffff;
	--login-header-color: #08134b;
	--login-highlight-color: #373cd9;

	--toast-button-background: #ffffff;
	--toast-button-hover: #e1e4ea;
	--toast-button-active: #dbdde6;

	--data-table-header-hover: #12193c;

	--counter-highlight-color: rgba(38, 42, 182, 0.8);

	// input component
	--input-text-color: var(--white);
	--input-background: var(--neutral-700);
	--input-counter-border-color: var(--primary-200);
	--input-counter-special-border-color: var(--yellow-600);
	--input-background-secondary: var(--neutral-900);
	--input-border-radius: var(--large-border-radius);
	--input-disabled-text-color: var(--neutral-400);
	--input-disabled-background: var(--neutral-850);
	--input-disabled-background-modal: var(--neutral-800);
	--input-placeholder-color: var(--neutral-300);
	--input-error-border-color: var(--red-400);
	--material-input-error-border-color: var(--red-600);
	--input-highlighted-border-color: var(--primary-200);
	--input-icon-color: var(--neutral-300);
	--input-label-text-color: var(--neutral-300);
	--input-error-label-text-color: var(--red-400);
	--input-label-required-text-color: var(--red-400);

	// date picker component
	--date-picker-background: var(--neutral-850);
	--date-picker-active-background: var(--purple-400);
	--date-picker-active-range-background: var(--purple-900);
	--date-picker-hover-background: var(--neutral-300);

	--table_row_gradient: linear-gradient(
		270deg,
		rgba(8, 19, 34, 0) 0%,
		rgba(55, 60, 217, 0.6) 100%
	);
}

@font-weight-bold: 700;
@font-weight-semibold: 600;
// TODO: refactor medium to regular properly
@font-weight-medium: 400;
@font-weight-regular: 400;
@font-weight-thin: 100;

//typography
:root {
	--font-base: 'Roboto', sans-serif;
	--font-weight-medium: @font-weight-medium;
	--font-weight-regular: @font-weight-regular;
	--font-weight-bold: @font-weight-bold;

	--h-1000-font-weight: @font-weight-medium;
	--h-1000-font-size: 26px;
	--h-1000-letter-spacing: 0.2px;
	--h-1000-line-height: 28.13px;

	--h-900-font-weight: @font-weight-medium;
	--h-900-font-size: 22px;
	--h-900-letter-spacing: 0.2px;
	--h-900-line-height: normal;

	--h-800-font-weight: @font-weight-medium;
	--h-800-font-size: 22px;
	--h-800-letter-spacing: 0.2px;
	--h-800-line-height: 26px;

	--h-700-font-weight: @font-weight-medium;
	--h-700-font-size: 16px;
	--h-700-letter-spacing: 0.2px;
	--h-700-line-height: 18.75px;

	--h-600-font-weight: @font-weight-medium;
	--h-600-font-size: 15px;
	--h-600-letter-spacing: 0.2px;
	--h-600-line-height: 17.58px;

	--h-500-font-weight: @font-weight-medium;
	--h-500-font-size: 14px;
	--h-500-letter-spacing: 0.2px;
	--h-500-line-height: 16.41px;

	--h-400-font-weight: @font-weight-medium;
	--h-400-font-size: 13px;
	--h-400-letter-spacing: 0.2px;
	--h-400-line-height: 15.23px;

	--h-300-font-weight: @font-weight-medium;
	--h-300-font-size: 13px;
	--h-300-letter-spacing: 0.2px;
	--h-300-line-height: 15.23px;

	--h-200-font-weight: @font-weight-medium;
	--h-200-font-size: 10px;
	--h-200-letter-spacing: 0.2px;
	--h-200-line-height: 12.89px;

	--t-200-font-weight: @font-weight-regular;
	--t-200-font-size: 14px;
	--t-200-letter-spacing: 0.2px;
	--t-200-line-height: 21.63px;

	--t-150-font-weight: @font-weight-medium;
	--t-150-font-size: 13px;
	--t-150-letter-spacing: 0.2px;
	--t-150-line-height: 15.23px;

	--t-100-font-weight: @font-weight-regular;
	--t-100-font-size: 13px;
	--t-100-letter-spacing: 0.2px;
	--t-100-line-height: 15.23px;

	--t-50-font-weight: @font-weight-medium;
	--t-50-font-size: 13px;
	--t-50-letter-spacing: 0.2px;
	--t-50-line-height: 18px;

	--m-200-font-weight: @font-weight-medium;
	--m-200-font-size: 10px;
	--m-200-letter-spacing: 0.2px;
	--m-200-line-height: 11.72px;

	--m-100-font-weight: @font-weight-regular;
	--m-100-font-size: 12px;
	--m-100-letter-spacing: 0.2px;
	--m-100-line-height: 15.23px;

	--m-50-font-weight: @font-weight-regular;
	--m-50-font-size: 12px;
	--m-50-letter-spacing: 0.2px;
	--m-50-line-height: 14.06px;
}

@media (min-width: 1920px) {
	:root {
		--h-1000-font-weight: @font-weight-medium;
		--h-1000-font-size: 30px;
		--h-1000-letter-spacing: 0.2px;
		--h-1000-line-height: 35.16px;

		--h-900-font-weight: @font-weight-medium;
		--h-900-font-size: 24px;
		--h-900-letter-spacing: 0.2px;
		--h-900-line-height: normal;

		--h-700-font-weight: @font-weight-medium;
		--h-700-font-size: 18px;
		--h-700-letter-spacing: 0.2px;
		--h-700-line-height: 21.09px;

		--h-600-font-weight: @font-weight-medium;
		--h-600-font-size: 16px;
		--h-600-letter-spacing: 0.2px;
		--h-600-line-height: 18.75px;

		--h-500-font-weight: @font-weight-medium;
		--h-500-font-size: 15px;
		--h-500-letter-spacing: 0.2px;
		--h-500-line-height: 17.58px;

		--h-400-font-weight: @font-weight-medium;
		--h-400-font-size: 14px;
		--h-400-letter-spacing: 0.2px;
		--h-400-line-height: 16.41px;

		--h-200-font-weight: @font-weight-medium;
		--h-200-font-size: 11px;
		--h-200-letter-spacing: 0.2px;
		--h-200-line-height: 12.89px;

		--t-200-font-weight: @font-weight-regular;
		--t-200-font-size: 15px;
		--t-200-letter-spacing: 0.2px;
		--t-200-line-height: 21.63px;

		--t-150-font-weight: @font-weight-medium;
		--t-150-font-size: 14px;
		--t-150-letter-spacing: 0.2px;
		--t-150-line-height: 16.41px;

		--t-100-font-weight: @font-weight-regular;
		--t-100-font-size: 14px;
		--t-100-letter-spacing: 0.2px;
		--t-100-line-height: 21px;

		--m-100-font-weight: @font-weight-regular;
		--m-100-font-size: 13px;
		--m-100-letter-spacing: 0.2px;
		--m-100-line-height: 15.23px;
	}
}

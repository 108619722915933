html,
body,
#root {
	font-family: var(--font-base);
	letter-spacing: 0.2px;
	width: 100vw;
	display: flex;
	flex-direction: column;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	background-size: cover;
	color: white;
}

body {
	font-family: var(--font-base);
	margin: 0;
}

body *:focus {
	outline: none;
}

body.logged-in {
	overflow: hidden;
}

body.logged-in #root {
	overflow-y: auto;
	overflow-x: hidden;
	height: 100vh;
	background-color: var(--neutral-1000);
	padding-top: 56px;
}

body.standalone #root {
	padding-top: 0;
}

.container {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
}

.white {
	color: #ffffff !important;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.spinner {
	animation-name: spin;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

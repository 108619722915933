.ui.thick.table tbody tr {
	height: 42px;
	@media (min-width: @widescreenMonitorBreakpoint) {
		height: 48px;
	}
}

.ui.striped.thick.table > tr:nth-child(2n),
.ui.striped.thick.table tbody tr:nth-child(2n) {
	background: var(--neutral-800);
}

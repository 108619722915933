/*******************************
         Theme Overrides
*******************************/

/* Dropdown Carets */
@font-face {
	font-family: 'Dropdown';
	src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAVgAA8AAAAACFAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAchGgaq0dERUYAAAF0AAAAHAAAAB4AJwAPT1MvMgAAAZAAAABDAAAAVnW4TJdjbWFwAAAB1AAAAEsAAAFS8CcaqmN2dCAAAAIgAAAABAAAAAQAEQFEZ2FzcAAAAiQAAAAIAAAACP//AANnbHlmAAACLAAAAQoAAAGkrRHP9WhlYWQAAAM4AAAAMAAAADYPK8YyaGhlYQAAA2gAAAAdAAAAJANCAb1obXR4AAADiAAAACIAAAAiCBkAOGxvY2EAAAOsAAAAFAAAABQBnAIybWF4cAAAA8AAAAAfAAAAIAEVAF5uYW1lAAAD4AAAATAAAAKMFGlj5HBvc3QAAAUQAAAARgAAAHJoedjqd2ViZgAABVgAAAAGAAAABrO7W5UAAAABAAAAANXulPUAAAAA1r4hgAAAAADXu2Q1eNpjYGRgYOABYjEgZmJgBEIOIGYB8xgAA/YAN3jaY2BktGOcwMDKwMI4jTGNgYHBHUp/ZZBkaGFgYGJgZWbACgLSXFMYHFT/fLjFeOD/AQY9xjMMbkBhRpAcAN48DQYAeNpjYGBgZoBgGQZGBhDwAfIYwXwWBgMgzQGETAwMqn8+8H649f8/lHX9//9b7Pzf+fWgusCAkY0BzmUE6gHpQwGMDMMeAACbxg7SAAARAUQAAAAB//8AAnjadZBPSsNAGMXfS+yMqYgOhpSuSlKadmUhiVEhEMQzFF22m17BbbvzCh5BXCUn6EG8gjeQ4DepwYo4i+/ffL95j4EDA+CFC7jQuKyIeVHrI3wkleq9F7XrSInKteOeHdda8bOoaeepSc00NWPz/LRec9G8GabyGtEdF7h19z033GAMTK7zbM42xNEZpzYof0RtQ5CUHAQJ73OtVyutc+3b7Ou//b8XNlsPx3jgjUifABdhEohKJJL5iM5p39uqc7X1+sRQSqmGrUVhlsJ4lpmEUVwyT8SUYtg0P9DyNzPADDs+tjrGV6KRCRfsui3eHcL4/p8ZXvfMlcnEU+CLv7hDykOP+AKTPTxbAAB42mNgZGBgAGKuf5KP4vltvjLIMzGAwLV9ig0g+vruFFMQzdjACOJzMIClARh0CTJ42mNgZGBgPPD/AJD8wgAEjA0MjAyogAMAbOQEAQAAAAC7ABEAAAAAAKoAAAH0AAABgAAAAUAACAFAAAgAwAAXAAAAAAAAACoAKgAqADIAbACGAKAAugDSeNpjYGRgYOBkUGFgYgABEMkFhAwM/xn0QAIADdUBdAB42qWQvUoDQRSFv3GjaISUQaymSmGxJoGAsRC0iPYLsU50Y6IxrvlRtPCJJKUPIBb+PIHv4EN4djKuKAqCDHfmu+feOdwZoMCUAJNbAlYUMzaUlM14jjxbngOq7HnOia89z1Pk1vMCa9x7ztPkzfMyJbPj+ZGi6Xp+omxuPD+zaD7meaFg7mb8GrBqHmhwxoAxlm0uiRkpP9X5m26pKRoMxTGR1D49Dv/Yb/91o6l8qL6eu5n2hZQzn68utR9m3FU2cB4t9cdSLG2utI+44Eh/P9bqKO+oJ/WxmXssj77YkrjasZQD6SFddythk3Wtzrf+UF2p076Udla1VNzsERP3kkjVRKel7mp1udXYcHtZSlV7RfmJe1GiFWveluaeKD5/MuJcSk8Tpm/vvwPIbmJleNpjYGKAAFYG7ICTgYGRiZGZkYWRlZGNkZ2Rg5GTLT2nsiDDEEIZsZfmZRqZujmDaDcDAxcI7WIOpS2gtCWUdgQAZkcSmQAAAAFblbO6AAA=)
		format('woff');
	font-weight: normal;
	font-style: normal;
}

.ui.dropdown > .dropdown.icon {
	font-family: 'Dropdown';
	line-height: 1;
	height: 1em;
	width: 1.23em;
	backface-visibility: hidden;
	font-weight: normal;
	font-style: normal;
	text-align: center;
}

.ui.dropdown > .dropdown.icon {
	width: auto;
}
.ui.dropdown > .dropdown.icon:before {
	/** toggle_icon.svg **/
	content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.70711 2.20952C9.31658 1.819 8.68342 1.819 8.29289 2.20952L5 5.50241L1.70711 2.20952C1.31658 1.819 0.683418 1.819 0.292894 2.20952C-0.0976307 2.60004 -0.0976307 3.23321 0.292894 3.62373L4.29289 7.62373C4.68342 8.01426 5.31658 8.01426 5.70711 7.62373L9.70711 3.62373C10.0976 3.23321 10.0976 2.60004 9.70711 2.20952Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
}

.ui.dropdown.disabled > .dropdown.icon:before {
	content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.70711 2.20952C9.31658 1.819 8.68342 1.819 8.29289 2.20952L5 5.50241L1.70711 2.20952C1.31658 1.819 0.683418 1.819 0.292894 2.20952C-0.0976307 2.60004 -0.0976307 3.23321 0.292894 3.62373L4.29289 7.62373C4.68342 8.01426 5.31658 8.01426 5.70711 7.62373L9.70711 3.62373C10.0976 3.23321 10.0976 2.60004 9.70711 2.20952Z' fill='%23373C62'/%3E%3C/svg%3E%0A");
} 

/* Sub Menu */
.ui.dropdown .menu .item .dropdown.icon:before {
	content: '\f0da' /*rtl:'\f0d9'*/;
}

.ui.dropdown .item .left.dropdown.icon:before,
.ui.dropdown .left.menu .item .dropdown.icon:before {
	content: '\f0d9' /*rtl:"\f0da"*/;
}

/* Vertical Menu Dropdown */
.ui.vertical.menu .dropdown.item > .dropdown.icon:before {
	content: '\f0da' /*rtl:"\f0d9"*/;
}

.ui.dropdown > .clear.icon:before {
	content: '\f00d';
	content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.70711 2.20952C9.31658 1.819 8.68342 1.819 8.29289 2.20952L5 5.50241L1.70711 2.20952C1.31658 1.819 0.683418 1.819 0.292894 2.20952C-0.0976307 2.60004 -0.0976307 3.23321 0.292894 3.62373L4.29289 7.62373C4.68342 8.01426 5.31658 8.01426 5.70711 7.62373L9.70711 3.62373C10.0976 3.23321 10.0976 2.60004 9.70711 2.20952Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
	content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.56066 0.439342C1.97487 -0.146445 1.02513 -0.146445 0.43934 0.439341C-0.146447 1.02513 -0.146446 1.97487 0.43934 2.56066L5.74282 7.86413L0.439581 13.1674C-0.146205 13.7532 -0.146205 14.7029 0.439581 15.2887C1.02537 15.8745 1.97511 15.8745 2.5609 15.2887L7.86414 9.98545L13.1673 15.2886C13.7531 15.8744 14.7028 15.8744 15.2886 15.2886C15.8744 14.7028 15.8744 13.753 15.2886 13.1673L9.98546 7.86413L15.2888 2.56077C15.8746 1.97498 15.8746 1.02523 15.2888 0.439444C14.703 -0.146343 13.7533 -0.146342 13.1675 0.439445L7.86414 5.74281L2.56066 0.439342Z' fill='%23ffffff'/%3E%3C/svg%3E");
}

/* Icons for Reference (Subsetted in 2.4.0)
  .dropdown.down:before { content: "\f0d7"; }
  .dropdown.up:before { content: "\f0d8"; }
  .dropdown.left:before { content: "\f0d9"; }
  .dropdown.right:before { content: "\f0da"; }
  .dropdown.close:before { content: "\f00d"; }
*/

/**
* Floating dropdown example -> chart filters
*/
.ui.dropdown.floating .menu > .header {
	margin-bottom: 6px;
}
.ui.dropdown.floating .menu > .item {
	font-size: var(--m-50-font-size);
	font-weight: var(--m-50-font-weight);
	letter-spacing: var(--m-50-letter-spacing);
	line-height: var(--m-50-line-height);
}

/*******************************
         Theme Overrides
*******************************/

/*--------------
   Secondary
---------------*/

/* Active */

.ui.secondary.menu.vertical {
	background: var(--neutral-1000);
}

.ui.secondary.menu.vertical .active.item:after,
.ui.secondary.menu.vertical .item:hover:after {
	content: ' ';
	display: block;
	width: 3px;
	height: 100%;
	border-radius: 2px;
	position: absolute;
	top: 0;
	right: 0;
	background: var(--primary-color);
}

/* Pagination arrows */
.ui.pagination.menu .item:first-child {
	color: transparent;
}
.ui.pagination.menu .item:first-child:after {
	content: '';
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M5 9L1 5L5 1' stroke='%23757BA9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath opacity='0.5' d='M11 9L7 5L11 1' stroke='%23757BA9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

	position: absolute;
	top: 8px;
	left: 6px;
	background-repeat: no-repeat;
	width: 12px;
	height: 10px;
}

.ui.pagination.menu .item:last-child {
	color: transparent;
}
.ui.pagination.menu .item:last-child:after {
	content: '';
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M5 9L1 5L5 1' stroke='%23757BA9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath opacity='0.5' d='M11 9L7 5L11 1' stroke='%23757BA9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
	transform: scaleX(-1);

	position: absolute;
	top: 8px;
	left: 8px;
	background-repeat: no-repeat;
	width: 12px;
	height: 10px;
}

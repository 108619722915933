/*******************************
         Theme Overrides
*******************************/

.ui.wide.left.sidebar.sidebar--notifications,
.ui.wide.right.sidebar.sidebar--notifications {
	width: 420px;

	@media (min-width: @widescreenMonitorBreakpoint) {
		width: 476px;
	}
}

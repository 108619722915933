/*******************************
         Theme Overrides
*******************************/

.ui.inverted.input > input,
.ui.inverted.selection.dropdown {
	color: @invertedInputColor;
	background-color: @invertedInputBackground;
}

.ui.inverted.selection.dropdown .menu > .item,
.ui.inverted.selection.active.dropdown {
	border-color: #fafafa;
	background-color: @invertedInputBackground;
	color: @invertedInputColor;
}

.ui.inverted.dropdown .menu {
	background: white;
}

.ui.inverted.selection.active.dropdown:hover {
	border-color: var(--primary-color);
}

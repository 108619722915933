article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

progress {
	vertical-align: baseline;
}

[hidden],
template {
	display: none;
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
	outline-width: 0;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

b,
strong {
	font-weight: inherit;
}

b,
strong {
	font-weight: 500;
}

dfn {
	font-style: italic;
}

mark {
	background-color: #ff0;
	color: #000;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.2em;
}

img {
	border-style: none;
}

svg:not(:root) {
	overflow: hidden;
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

figure {
	margin: 1em 40px;
}

hr {
	border: 0;
	border-bottom: 1px solid #d3d3d3;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

button,
input,
select,
textarea {
	font: inherit;
	margin: 0;
}

optgroup {
	font-weight: 700;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

[type='reset'],
[type='submit'],
button {
	-webkit-appearance: button;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
	outline: 1px dotted ButtonText;
}

fieldset {
	border: 1px solid silver;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}

textarea {
	overflow: auto;
}

[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

*,
:after,
:before {
	box-sizing: border-box;
}

:focus {
	outline: 0;
}

img {
	border: 0;
	-ms-interpolation-mode: bicubic;
	max-width: 100%;
}

button {
	background: 0 0;
	border: none;
	cursor: pointer;
	outline: 0;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

b,
strong {
	font-weight: 700;
}

a {
	color: #fff;
	cursor: pointer;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

[type='button'] {
	-webkit-appearance: button;
}

button[disabled],
input[disabled] {
	cursor: not-allowed;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	line-height: normal;
	padding: 0;
}

/*******************************
         Theme Overrides
*******************************/

.ui.button {
	display: flex;
	align-items: center;
	justify-content: center;
}

@groupPadding: 4px;

.ui.toggle.buttons {
	&.disabled {
		background: @disabledBackgroundColor;
	}

	--group-padding: 3px;
	padding: var(--group-padding);
	border-radius: var(--default-border-radius);
	background-color: var(--neutral-700);
	.ui.button:first-child {
		border-radius: @borderRadius;
	}
	.ui.button:last-child {
		border-radius: @borderRadius;
	}
	.ui.button:not(.active) {
		background: none !important;
		color: var(--text-color-secondary);
	}
	.ui.button.active.disabled {
		background: rgba(55, 60, 98, 0.3) !important;
		color: rgba(117, 123, 169, 0.4) !important;
	}
	.ui.button.disabled {
		color: rgba(117, 123, 169, 0.4) !important;
	}
	.ui.button.active {
		background: linear-gradient(180deg, var(--primary-color) 0%, #243afa 100%) !important;
	}

	// TODO: calc from variables Normal height - group padding
	.ui.button {
		padding: @groupPadding 24px;
		font-size: var(--h-400-font-size);
		line-height: var(--h-400-line-height);
		font-weight: var(--h-400-font-weight);
		letter-spacing: var(--h-400-letter-spacing);
	}
}
